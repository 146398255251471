import React from 'react';
import emailjs from 'emailjs-com';
import Typewriter from "typewriter-effect";
import './ContactMe.css';
import Encrypted from './Encrypted';

export default function ContactMe() {

    function sendEmail(e) {
        e.preventDefault();

    emailjs.sendForm('service_91eswic', 'template_2m7xqoa', e.target, 'user_jeoDkFPXLTeRakyhWybWJ')
        .then((result) => {
        }, (error) => {
        });
        e.target.reset()
    }
  
    return(
        <div id="contact">
            <div className="contactheader">
            <Typewriter
                options={{
                    strings: ['Get in touch_'],
                    autoStart: true,
                    delay: 100,
                    loop: true,
                }}
                />
            </div>
            <div className="contact row">
                <div className="cdetails column">
                    <div className="contact_details">
                        <div className="phone">
                            <h4>PHONE: </h4>&nbsp;&nbsp;&nbsp; &nbsp;
                            <a href="tel:+61403079164">+61403079164</a>
                        </div>
                        <div className="email">
                        <h4>EMAIL: </h4>&nbsp;&nbsp;&nbsp; &nbsp;
                            <a href="mailto:root@sidharthkaushik.com">root@sidharthkaushik.com</a>
                        </div>
                    </div>
                    <div className="socials">
                        <div className="linkedin">
                            <a href="https://www.linkedin.com/in/sidharth-kaushik/">LinkedIn</a>
                        </div>
                        <div className="github">
                            <a href="https://github.com/rootsid">GitHub</a>
                        </div>
                    </div>
                    <div class="anaDiv">
                        <div class="icerik">
                            <p id="baslik">Sidharth Kaushik</p>
                        <a target="blank" href="https://twitter.com/thequantumscientist"><img alt="twitter" draggable="false" class="ikon" id="tw" src="https://cdn2.iconfinder.com/data/icons/black-white-social-media/32/twitter_online_social_media-512.png" /></a>
                        
                        <a target="blank" href="https://github.com/rootsid"><img alt="github" draggable="false" class="ikon" id="gh" src="https://cdn2.iconfinder.com/data/icons/black-white-social-media/64/social_media_logo_github-512.png" /></a>
                        
                        <a target="blank" href="https://www.instagram.com/phantombeasthacker/"><img alt="instagram" draggable="false" class="ikon" id="ig" src="https://cdn2.iconfinder.com/data/icons/black-white-social-media/32/instagram_online_social_media_photo-512.png" /></a>
                        
                        <a target="blank" href="mailto:root@sidharthkaushik.com"><img alt="mail" draggable="false" class="ikon" id="em" src="https://cdn4.iconfinder.com/data/icons/black-white-social-media/32/mail_email_envelope_send_message-512.png" /></a>
                        </div>
                        <div class="kapak"></div>
                    </div>
                </div>
                <div className="cform column">
                    <form onSubmit={sendEmail}>
                            <div className="contactform">
                                <div className="form-group mx-auto fields">
                                    <input type="text" required="required" className="form-control" placeholder="Your Name" name="name"/>
                                </div>
                                <div className="form-group pt-2 mx-auto fields">
                                    <input type="email" required="required" className="form-control" placeholder="Your Email" name="email"/>
                                </div>
                                <div className="form-group pt-2 mx-auto fields">
                                    <input type="text" required="required" className="form-control" placeholder="Subject" name="subject"/>
                                </div>
                                <div className="form-group pt-2 mx-auto fields">
                                    <textarea className="form-control" required="required" id="" cols="30" rows="8" placeholder="Your message" name="message"></textarea>
                                </div>
                                <div className="mx-auto btnfield">
                                    <input type="submit" className="btn btn-info contactbtn" value="Send"></input>
                                </div>
                            </div>
                    </form>
                </div>
                
            </div>
            <Encrypted />
        </div>
    )
  }

