import React from 'react';
import './Trial.css';

function Trial() {
  return <div className='anim'>
      <section>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </section>
  </div>;
}

export default Trial;
