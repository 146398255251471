import React from 'react'
import './Encrypted.css'

function Encrypted() {
    return (
        <div className="encrypted__box">
            <h4>Want to Send Encrypted Email ?</h4>
            <hr />
            <p>-----BEGIN PGP PUBLIC KEY BLOCK-----</p>
            <p className="key">
                xsFNBF/Ka20BEAC8ST3SZvMiaewylc56i7F3FT7NIGdTJZ2qWpgwG8x64XNJIM30NAloFWwvNWou
                KDUxroTM/uezrE/ALp+G8RQed85G0b2HkYjViuErRUMaCAh5+/f4EK3FG/lzf5nCuHb4nL9SwdR5
                HNFbOeu4TkTsxz4kz9SJBlSNTqU0Re5s2jA0Sy3T18hD2D8HepXzM6nUSH1zx8K0LhHVxo1YmiVa
                tI1S0AcUE+wER1eFVTpJv9yno2KcrYpvOEDUysWcJsYF6Q/NP8RqEI/MhtRWo4S56OtqP4qAh6Cm
                aztJlH3LdWyPDNY67vY0mHQKTeTo7gdifbfKIdNBulJvlGSMTfNi44sAR8CDG3Y3PRcUASBzivng
                XF1N2C4Syk/qfv0ZFLF845W9ME/WEbonVOGet2FI+fcP/bKmuFjX8Gp8QmLNhaJy1ohYrb3zXyHJ
                FMSigVJ9NALi5ftIaiXcBc1As6JwYBv3xTptIIPGhLBLxFSyx5DEa0TTjkjCrFVMv3giwj85IjSs
                gYjb+x+QkwIIwyHWXHQeZzc3eM+fN+yn6q03WvdvI5/dR0XpEpzfpNsC7sVxgkTphP6i9wqv4AuS
                3OQvU9cvNVDyzft5PTfJhSA4f6KU7MiU3yDbHzm/OWy895m2WM+W6NGCEDMRbeCzeTuQrcHTZc8p
                a+vqGziH2VQ2AwARAQABzStTaWRoYXJ0aCBLYXVzaGlrIDxyb290QHNpZGhhcnRoa2F1c2hpay5j
                b20+wsGJBBMBCAAzFiEEQt4KyOcRVeg0+g9+GnV22FfrtwQFAl/Ka3ECGwMFCwkIBwIGFQgJCgsC
                BRYCAwEAAAoJEBp1dthX67cEGtkP/1Y6AUmKmN2B7klEDZ11b1IhQuGk+/7myWrA3JK0eD21Yl/H
                BUuFqHDwIgW0lJTCFxN6yjaBhpkTtEweiql/P4dvGGIA5HHX66itjHEtRRCU+lE5Qq3KmP76YSOS
                NfeKcWG2JB7sO+KFgsDP7NFNjYuNbZJNLfVuO/z56x/jdIEsOxo5Dq6cb5jB2qXNXhUcDN+CvGYu
                2j3aNbRWVAviMHU2KMW5g+Ulp+Y3FTJ86QCqkxXfHbv2SHkpmdSQGmrdRjNy+26yszFYN2hJJTcW
                88oZ8JMgvGcgY+Z9d1TVwtQoxIiN3guz38d8wmeVerk0yhc9WNpqiJxJfx/n+yPLZZZdAHUGpxpJ
                XC9OJdefWWaOsofYQfAnJDye7CsDvnJ+9Wjm7Z23tUtYKyPTmigwYq80aLe0gdzmJjOqI7EYiR1b
                An+Sa4dRfJsQROFpnczc/KklsaOJMwfUn79PErLqUOrruPqkt9yUD3aDTrPNDUsi4OtY8yfymDQf
                zh7mX5mzs7P0A/D65HHBG+bKPJGXsCjorvn7cwRTYKc/EYTAXa2X+J9Kmiv6YDxHC5rlh/O3BksL
                gwgRzzCreBcfylu6efOA+z1kRYAiUrXdAPcRKP8jVYeX7Hi4nnq/EOHrxuzkX3vjM6OFyS3nI35a
                MX9SGjnUsi7bQnFRued5aGQYX2G4zsFNBF/Ka3EBEAC7u2IaTXjRkX4hGTfgqfCH2UvIlLs+Ospg
                /Tml1tARV0oe/O37o0VuLhDieJSYWLu1QbD+pUzIRbqlKvab+Dd4XgSEtPpkrqOEkxehswoQbsJl
                B5RYlALqLOy9LQO+pBU2kYVX/BgtpzCHoLkuAoon/gH4zxb3OqDsi5OOG/DgyNUKTqmuOoKJF8/g
                5P5hbq6M5Q3B4Gp3c3IsUn3EoXcbShcCl7JCrfSLdqqtWNu2lmK05UUjaULEF70tHVQKwIP3lZ3L
                YA37PIOKpC5+4/gfGe9QbwEQGgtQ1NZGnQnU2uLH636E58HuN3OwISUKBnamOy3TFp2iiva8Oeec
                vZ1BoPmTR8T5Q0E8JrMWgklbD+qCHZmAEceiI06tdEtufoKhVUbFP/UE9kcvRFVMHH7Pl02MryOm
                rEiubdvDEUqEk5zzshQWXaL6DIJDgLQ/K8+6+Z3Lev/TRUHbJm9OXe2AmNUqurbwnJZ/1t52fBQp
                H4FRAQprdFAKKLDiOh6jw0PnRl8Vxr/kf2igt8kCA5pgpp33PZV8fGBLxM7OrYYbWXmwupff58hV
                6VdN3FV9HTHSlvcA1msg/y03podwolUp8E+DbKsvmmG8nWTBu3MDy7KeAzQA7AY7TTPm7KnWC5uO
                dhI5SYRcgzlYJLt22MNpIWqCrwZWVDJpw2Yt+e/tCQARAQABwsF2BBgBCAAgFiEEQt4KyOcRVeg0
                +g9+GnV22FfrtwQFAl/Ka3kCGwwACgkQGnV22FfrtwRI2A//cCvZr6ljkm7giObn2MHySXLMUXl6
                2QmpdAI1xlU6FyQhQHRh0cPXFfdvbGWdxq+P8lnoHoPX969KjIlAiSqFL30eRCxnuhZjFyDFkZ0t
                hSoTBtVUBJA8cuIUQyL6JqRSWfm2vrw4fALRXd6qi/qBKw5t1h1RR4Wb9ERufyCzuw6HyAAvdtYZ
                p675igREVjPPh0WZ1JD+KW1HPijd8xavbt8syiq7PdSa9V3Nz3O6hqp/bEDhx7QnzQ5w2ONNhmKs
                6ebb1hK9aJRjtCOsq+6/b8jvzoJuvZcCn/mjy04/ErGMJdqPwkcXGBDQ68lSGm9zUMbyvlIbnTNV
                xjwfMbydtpurfIKX4eaeQlqbU9ORbijmTWGGXtHvDY7dIL3bXG1pXgSU4nwYPwEzEup53leBWbyB
                tYah83cq4ElpiG4Z5VWbrG2lvd5KuED/+pCkTkNKM0+dCaJt2qoXirZSui0rAEJyQyPm6BoiSfPe
                ixBgknqfzqNuIzbv2j1Sk+xh/jVOeTpk7WBZPkHLhYLZHl0YlRoBZ1KD7t6B/zrmjNiE8v+1v702
                e37qftUf5MjzAB0QFbn1cSK8c8ibMc5L1pF6ObAvhwhMg0uyJ40ZVh8ck9T429Gd5YGZ8ZecEITi
                U8vVq6vYDkfg9GK95gmu7ZN4JzlnxDXc33MX8SvKjNAUaZE=
                =kQRH
            </p>
            <p>-----END PGP PUBLIC KEY BLOCK-----</p>
        </div>
    )
}

export default Encrypted
