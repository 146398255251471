let timelineElements = [
    {
        id: 1,
        title: "Masters of Research in Science & Engineering",
        location: "Sydney, Australia",
        description: "",
        buttonText: "Program Website",
        date: "Febraury 2022 - March 2024",
        icon: "school",
        link: "https://www.mq.edu.au/research/phd-and-research-degrees/research-training"
    },
    {
      id: 2,
      title: "Atos | Senior Consultant R&D",
      location: "Sydney, Australia",
      description: "",
      buttonText: "Company Page",
      date: "Febraury 2022 - Present",
      icon: "work",
      link: "https://atos.net/en/"
    },
    {
        id: 3,
        title: "Atos | Consultant R&D",
        location: "Bangalore, India",
        description:
          "",
        buttonText: "Company Page",
        date: "January 2021 - December 2021",
        icon: "work",
        link: "https://atos.net/en/"
    },
    {
        id: 4,
        title: "Atos | Associate Consultant",
        location: "Bangalore, India",
        description:
          "",
        buttonText: "Company Page",
        date: "November 2019 - December 2020",
        icon: "work",
        link: "https://atos.net/en/"
    },
    {
      id: 5,
      title: "DXC | Technology Consultant",
      location: "Bangalore, India",
      description:
        "",
      buttonText: "Company Page",
      date: "November 2018 - October 2019",
      icon: "work",
      link: "https://www.dxc.technology/"
    },
    {
      id: 6,
      title: "Inch Factory | Software Developer",
      location: "Bangalore, India",
      description:
        "",
      buttonText: "Company Page",
      date: "September 2018 - Novemeber 2018",
      icon: "work",
      link: "https://www.linkedin.com/company/inchfactory"
    },
    {
      id: 7,
      title: "Microsoft | Support Engineer",
      location: "Bangalore, India",
      description:
        "",
      buttonText: "Company Page",
      date: "July 2018 - Aug 2018",
      icon: "work",
      link: "https://www.microsoft.com/"
    },
    {
      id: 8,
      title: "SID CORP | Founder",
      location: "Haryana, India",
      description:
        "",
      buttonText: "Company Page",
      date: "December 2017 - June 2018",
      icon: "work",
      link: "https://www.linkedin.com/company/sidcorp"
    },
    {
      id: 9,
      title: "Technological Institure of Textile & Sciences",
      location: "Haryana, India",
      description:
        "Graduated in Computer Science with First Class Degree.",
      buttonText: "College Projects",
      link: "https://www.github.com/rootsid",
      date: "June 2014 - June 2018",
      icon: "school",
    },
    {
      id: 10,
      title: "High School",
      location: "Haryana, India",
      description: "",
    //   buttonText: "College Projects",
      date: "April 2012 - March 2014",
      icon: "school",
    },
  ];
  
  export default timelineElements;